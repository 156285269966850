import Box from '@paperstac/ui/lib/Box';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import styled from 'styled-components';
import { border, borderRadius } from 'styled-system';
import { ReactComponent as InventorySvg } from '../../../images/homepage-feature-inventory.svg';
import { ReactComponent as PriceSvg } from '../../../images/homepage-feature-price.svg';
import { ReactComponent as TransactionsSvg } from '../../../images/homepage-feature-transactions.svg';

const FeaturesSection = React.memo((props) => {
  return (
    <Container>
      <Flex flexDirection={['column', 'column', 'row']} pt={[4, 4, 5, 6]} pb={[4, 4, 5, 6]} mx={[0, 0, -12, -32]}>
        <Feature
          mb={[4, 4, 0]}
          mx={[0, 0, 12, 32]}
          icon={
            <Box height={[80, 100, 80, 120, 150]} width={[103, 129, 103, 155, 193]}>
              <InventorySvg width="100%" height="100%" />
            </Box>
          }
          heading="Easy Access to Inventory"
          body="Powerful search filters enable you to find the perfect asset. You can even setup alerts to get notified when a note is listed that matches your buying criteria."
        />
        <Feature
          mb={[4, 4, 0]}
          mx={[0, 0, 12, 32]}
          icon={
            <Box height={[80, 100, 80, 120, 150]} width={[75, 94, 75, 113, 141]}>
              <TransactionsSvg width="100%" height="100%" />
            </Box>
          }
          heading="Online Transactions"
          body='Close your deals without leaving your computer! Our online closing is simply the fastest, easiest and dare we say "funnest" way to buy and sell mortgage notes.'
        />
        <Feature
          mx={[0, 0, 12, 32]}
          icon={
            <Box height={[80, 100, 80, 120, 150]} width={[117, 146, 117, 175, 219]}>
              <PriceSvg width="100%" height="100%" />
            </Box>
          }
          heading="Cost-Effective Solution"
          body="Free to bid and list. You’ll pay a small 1.85% fee ($375 minimum) during closing. The fee covers all FedEx shipments, audit reports, online notary and more!"
        />
      </Flex>
    </Container>
  );
});

const StyledFeature = styled(Flex)`
  ${border}
  ${borderRadius}
`;

const Feature = ({ heading, body, icon, ...props }) => (
  <StyledFeature
    flexDirection={['column', 'row', 'column']}
    alignItems="center"
    border={['none', 'default', 'none', 'default']}
    borderRadius="corner"
    bg={['transparent', 'white', 'transparent', 'white']}
    padding={[0, 4, 0, 4, 5]}
    mt={[0, 0, 0, 84, 108]}
    {...props}
  >
    <Flex
      mt={[0, 0, 0, -84, -108]}
      mr={[0, 4, 0]}
      mb={[2, 2, 2, 4]}
      justifyContent={['center']}
      flex={['initial', '0 0 150px', 'initial']}
    >
      {icon}
    </Flex>
    <Box>
      <Heading fontSize={[20, 20, 18, 24, 32]} mb={[2, 2, 2, 4]} textAlign={['center', 'left', 'center']}>
        {heading}
      </Heading>
      <Text fontSize={[14, 16, 14, 16, 20]} textAlign={['center', 'left', 'center']}>
        {body}
      </Text>
    </Box>
  </StyledFeature>
);

FeaturesSection.propTypes = {};

FeaturesSection.defaultProps = {};

FeaturesSection.displayName = 'FeaturesSection';

export default FeaturesSection;
