import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import { HOME } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Link from '@paperstac/ui/lib/Link';
import React from 'react';
import useMedia from 'use-media';
import { ReactComponent as Branding } from '../../../images/paperstac.svg';
import { IdentityManagerContext } from '../IdentityManager';
import AccountDropdown from './AccountDropdown';
import Nav from './Nav';
import XIcon from '../../icons/XIcon';
import createPersistedState from '@paperstac/hooks/lib/createPersistedState';

const usePricingDismissed = createPersistedState('PricingDismissed');

const Header = React.memo((props) => {
  const { isMobile } = useBreakpoints();
  const { currentAccount } = React.useContext(AccountsContext);
  const { showRegister } = React.useContext(IdentityManagerContext);
  const isTall = useMedia({ minHeight: 800 });

  const [pricingDismissed, setPricingDismissed] = usePricingDismissed('no');
  const isLoggedIn = !!currentAccount;
  const isBeforePriceBannerExpiry = new Date().getTime() < new Date('2025-03-01').getTime();
  const isPricingDismissed = pricingDismissed === 'yes';
  const showPriceUpdate = isLoggedIn && isBeforePriceBannerExpiry && !isPricingDismissed;
  const handlePricingDismissed = () => setPricingDismissed('yes');

  return (
    <>
      {!!showPriceUpdate && (
        <Box bg="gray.1" fontSize={2} py={1}>
          <Container>
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'center' }}>
              <p>
                We’ve updated our pricing for deals completed on Paperstac.{' '}
                <Link href="https://support.paperstac.com/en/articles/774149-the-fee-what-s-included" target="_blank">
                  View Pricing
                </Link>
                .
              </p>
              <Button
                onClick={handlePricingDismissed}
                sx={{
                  bg: 'transparent',
                  p: 0,
                  display: 'block',
                  color: '#CAA',
                  '&:hover': { bg: 'transparent', color: '#C00' },
                }}
              >
                <XIcon style={{ display: 'block' }} size={16} />
              </Button>
            </div>
          </Container>
        </Box>
      )}
      <Box
        bg="white"
        sx={{
          width: '100vw',
          pt: [3, 3, 5],
          pb: [50, 53, 5],
          position: isTall ? 'sticky' : 'relative',
          top: 0,
          zIndex: 3,
        }}
      >
        <Container as={Flex} alignItems="center">
          <Box flex={1}>
            <Link to={HOME}>
              <Branding height={isMobile ? 24 : 30} style={{ verticalAlign: 'middle' }} />
            </Link>
          </Box>
          <Flex alignItems="center" display={['block']}>
            <Nav />
            {!currentAccount && (
              <Button onClick={showRegister} variant="primary" size="small">
                Register
              </Button>
            )}
            {!!currentAccount && <AccountDropdown />}
          </Flex>
        </Container>
      </Box>
    </>
  );
});

Header.propTypes = {};

Header.defaultProps = {};

Header.displayName = 'Header';

export default Header;
